import request from '@/tools/request';

export const getListData = (data, loading) => {
  return request({
    url: '/giftcard-config-goodsinfo.html',
    method: 'post',
    data,
    loading,
  });
};

export const getEditListData = (params, loading) => {
  return request({
    url: '/giftcard-config-offerinfo.html',
    params,
    loading,
  });
};

export const getSelectData = () => {
  return request({
    url: '/giftcard-config-offerlist.html',
  });
};

export const saveEditData = (data, loading) => {
  return request({
    url: '/giftcard-config-giftcardedit.html',
    data,
    loading,
    method: 'post',
  });
};

export const saveCreateData = (data, loading) => {
  return request({
    url: '/giftcard-config-giftcardadd.html',
    data,
    loading,
    method: 'post',
  });
};

export const getCouponCodeList = (params, loading) => {
  return request({
    url: '/giftcard-config-offerlists.html',
    params,
    loading,
  });
};
